export default {
  wormhole: {
    wallet: {
      connect: 'Connect Wallet',
      install: 'Install Phantom'
    },
    transfer: {
      selectSource: 'Select Source',
      selectDestination: 'Select Destination',
      title: 'Simple Wormhole Transfer',
      sendAsset: 'Attest and Transfer assets through Wormhole.',
      enterAddress: 'Enter address',
      shouldAttest: 'Should Attest?',
      fromInjective: 'From Injective?',
      selectToken: 'Select Token',
      memesBridge: 'Memes Bridge',
      memesBridgeNote: 'Injective <> Solana memes bridge',
      sender: 'Sender',
      receiver: 'Receiver',
      shouldCw20Wrap:
        'Should create CW20 Wrap? (use it only if CW20 wrap doesnt exist)'
    },
    redeem: {
      redeem: 'Redeem',
      advancedTools: 'Advanced Tools',
      advancedToolsDescription:
        'You can use these tool to unwrap your CW20 assets.',
      successful: 'Redeem Successful'
    }
  }
}
