import {
  StateTree,
  PiniaPluginContext,
  SubscriptionCallback,
  SubscriptionCallbackMutationPatchObject
} from 'pinia'
import { StatusType } from '@injectivelabs/utils'
import { defineNuxtPlugin } from '#imports'
import { localStorage } from '@/app/Service'

const stateToPersist = {
  app: {
    devMode: false
  },
  pendingTransaction: {
    transactions: []
  },
  sharedGeo: {
    geoCountry: '',
    geoContinent: ''
  },
  sharedWallet: {
    session: '',
    address: '',
    addresses: '',
    wallet: 'metamask',
    injectiveAddress: '',
    addressConfirmation: ''
  }
} as Record<string, Record<string, any>>

const actionsThatSetWalletQueueToBusy = [
  'bridge/deposit',
  'bridge/withdraw',
  'bridge/peggySetTokenAllowance',
  'transfer/transferBank',
  'bank/transfer',
  'bank/redeemCw20ToFactory',
  'wormhole/transferSplToken',
  'wormhole/transferErc20Token',
  'wormhole/transferInjectiveToken'
]

const persistState = (
  mutation: SubscriptionCallbackMutationPatchObject<StateTree>,
  state: StateTree
) => {
  if (!stateToPersist[mutation.storeId]) {
    return
  }

  const keysToPersist = Object.keys(stateToPersist[mutation.storeId])

  if (!mutation.payload) {
    return
  }

  const shouldPersistState =
    keysToPersist.length > 0 &&
    Object.keys(mutation.payload).some((key) => {
      return keysToPersist.includes(key)
    })

  if (!shouldPersistState) {
    return
  }

  const updatedState = keysToPersist.reduce((stateObj, key) => {
    return {
      ...stateObj,
      [key]: mutation.payload[key] || state[key]
    }
  }, {})

  const existingState = (localStorage.get('state') || {}) as any

  localStorage.set('state', {
    ...stateToPersist,
    ...existingState,
    [mutation.storeId]: updatedState
  })
}

function piniaStoreSubscriber({ store }: PiniaPluginContext) {
  const localState = localStorage.get('state') as any
  const sharedWalletStore = useSharedWalletStore()

  if (localState[store.$id]) {
    store.$state = { ...store.$state, ...localState[store.$id] }
  }

  store.$subscribe(persistState as SubscriptionCallback<StateTree>)

  store.$onAction(({ name, store: { $id }, after, onError }) => {
    after(() => {
      if (actionsThatSetWalletQueueToBusy.includes(`${$id}/${name}`)) {
        sharedWalletStore.$patch({
          queueStatus: StatusType.Idle
        })
      }
    })

    onError(() => {
      if (actionsThatSetWalletQueueToBusy.includes(`${$id}/${name}`)) {
        sharedWalletStore.$patch({
          queueStatus: StatusType.Idle
        })
      }
    })
  }, true)
}

export default defineNuxtPlugin(
  ({
    vueApp: {
      config: { globalProperties }
    }
  }) => {
    globalProperties.$pinia.use(piniaStoreSubscriber)
  }
)
