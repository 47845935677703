import {
  Network,
  isDevnet,
  isTestnet,
  CW20_ADAPTER_CONTRACT_BY_NETWORK
} from '@injectivelabs/networks'

export const NETWORK: Network =
  (import.meta.env.VITE_NETWORK as Network) || Network.Devnet
export const IS_DEVNET = isDevnet(NETWORK)
export const IS_TESTNET = isTestnet(NETWORK)

export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production'

export const CW20_ADAPTER_CONTRACT = CW20_ADAPTER_CONTRACT_BY_NETWORK[
  NETWORK
] as string
/** todo */
export const CW20_WRAPPER_CONTRACT =
  'inj182hvp064stesu55clqnzhm68vj84qgt0jxf0ln'
export const SOL_ALCHEMY_KEY = import.meta.env.VITE_SOL_ALCHEMY_KEY
export const TRITON_RPC_KEY = import.meta.env.VITE_TRITON_RPC_KEY
export const DEV_TRITON_RPC_KEY = import.meta.env.VITE_DEV_TRITON_RPC_KEY

export const PEGGY_GRAPH_URL = (): string => {
  if (IS_TESTNET) {
    // Note: this endpoint doesn't work. ticket for chain team :https://injective-labs.atlassian.net/browse/ECO-159
    return 'https://api.studio.thegraph.com/query/73355/injective-peggo-sepolia/version/latest'
  }

  return 'https://api.studio.thegraph.com/query/82145/injective-peggy/v1.0.0'
}

export const MIXPANEL_KEY = import.meta.env.VITE_MIXPANEL_KEY || ''
export const MERCURYO_KEY = import.meta.env.VITE_MERCURYO_KEY || ''
export const MERCURYO_WIDGET_ID = import.meta.env.VITE_MERCURYO_WIDGET_ID || ''
export const MAINTENANCE_ENABLED: boolean =
  import.meta.env.VITE_MAINTENANCE_ENABLED === 'true'
