import { NUMBER_REGEX } from '@shared/utils/constant'
import { BigNumberInBase } from '@injectivelabs/utils'
import { getEthereumAddress } from '@injectivelabs/sdk-ts'
import { defineRule } from 'vee-validate'
import { defineNuxtPlugin } from '#app'
import { bech32 } from 'bech32'
import { Network } from '@shared/types'
import { getNetworkFromAddress } from '@shared/utils/network'
import {
  isValidInjDomainName,
  isValidInjBonfidaDomainName
} from '@/app/utils/nameService'
import { INJ_REQUIRED_FOR_GAS } from '@/app/utils/constants'

const formatInvalidAddressMsg = (network: string) =>
  `This field is not a valid ${network} address`

export const errorMessages = {
  incorrectFormat: () => 'Incorrect format',
  maxBalance: () => 'Insufficient balance',
  maxBalanceWithGasBuffer: () =>
    `Insufficient balance. Gas fees are ${INJ_REQUIRED_FOR_GAS} INJ`,
  maxUsdAmount: (amount: string) => `Amount must be under $${amount}`,
  ethAddress: () => `This field is not a valid Ethereum address`,
  injName: () => 'This field is not a valid Injective name',
  injBonfidaName: () => 'This field is not a valid Injective name',
  [Network.Kava]: () => formatInvalidAddressMsg('Kava'),
  [Network.Evmos]: () => formatInvalidAddressMsg('Evmos'),
  [Network.Noble]: () => formatInvalidAddressMsg('Noble'),
  [Network.Canto]: () => formatInvalidAddressMsg('Canto'),
  [Network.Axelar]: () => formatInvalidAddressMsg('Axelar'),
  [Network.Stride]: () => formatInvalidAddressMsg('Stride'),
  [Network.Kujira]: () => formatInvalidAddressMsg('Kujira'),
  [Network.Osmosis]: () => formatInvalidAddressMsg('Osmosis'),
  [Network.CosmosHub]: () => formatInvalidAddressMsg('Cosmos'),
  [Network.Ethereum]: () => formatInvalidAddressMsg('Ethereum'),
  [Network.Crescent]: () => formatInvalidAddressMsg('Crescent'),
  [Network.Celestia]: () => formatInvalidAddressMsg('Celestia'),
  [Network.Andromeda]: () => formatInvalidAddressMsg('Andromeda'),
  [Network.Injective]: () => formatInvalidAddressMsg('Injective'),
  [Network.Sommelier]: () => formatInvalidAddressMsg('Sommelier'),
  [Network.Oraichain]: () => formatInvalidAddressMsg('Oraichain'),
  [Network.Secret]: () => formatInvalidAddressMsg('Secret Network'),
  [Network.Persistence]: () => formatInvalidAddressMsg('Persistence'),
  [Network.EvmosTestnet]: () => formatInvalidAddressMsg('Evmos'),
  [Network.XionTestnet]: () => formatInvalidAddressMsg('Xion'),
  [Network.Xion]: () => formatInvalidAddressMsg('Xion'),
} as Record<string, any>

const formatFieldName = ({
  field,
  label
}: {
  field: string
  label?: string
}) => {
  if (label) {
    return label
  }

  if (field.includes('-')) {
    return field.replaceAll('-', ' ')
  }

  return field.replace(/[^a-z]+/gi, '')
}

export const defineGlobalRules = () => {
  defineRule(
    'required',
    (
      value: string | number,
      [label]: [string | undefined],
      { field }: { field: string }
    ) => {
      if (!value || !value.toString().length || Number(value) === 0) {
        if (field.toLowerCase().includes('amount')) {
          return 'amount is required'
        }

        return `${formatFieldName({ field, label })} is required`
      }

      return true
    }
  )

  defineRule('injAddress', (value: string) => {
    const errorMessage = 'Invalid INJ address'

    if (value?.startsWith('0x') || !value?.startsWith('inj')) {
      return errorMessage
    }

    try {
      if (getEthereumAddress(value)) {
        return true
      } else {
        return errorMessage
      }
    } catch (error: any) {
      return errorMessage
    }
  })

  defineRule('injName', (value: string) => {
    if (!value) {
      return true
    }

    if (!isValidInjDomainName(value)) {
      return errorMessages.injName(value)
    }

    return true
  })

  defineRule('injBonfidaName', (value: string) => {
    if (!value) {
      return true
    }

    if (!isValidInjBonfidaDomainName(value)) {
      return errorMessages.injBonfidaName(value)
    }

    return true
  })

  defineRule('addressByNetwork', (value: string, [network]: Network[]) => {
    if (network === Network.Ethereum) {
      if (!value.startsWith('0x') || value.length !== 42) {
        return errorMessages[network]()
      }

      return true
    }

    // cosmos address
    try {
      const networkFromAddress = getNetworkFromAddress(value)

      if (networkFromAddress !== network) {
        return errorMessages[network]()
      }

      bech32.decode(value)

      return true
    } catch (error) {
      if (errorMessages[network]) {
        return errorMessages[network]()
      }
    }
  })

  defineRule('ethOrPeggyAddress', (value: string) => {
    const formattedAddress = value.replace('peggy', '')

    if (!formattedAddress.startsWith('0x') || formattedAddress.length !== 42) {
      return errorMessages.ethAddress()
    }

    return true
  })

  defineRule('isNumber', (value: string) => {
    if (value && !NUMBER_REGEX.test(value)) {
      return errorMessages.incorrectFormat()
    }

    return true
  })

  defineRule(
    'min',
    (
      value: string,
      [min, label]: [number | string, string | undefined],
      { field }: { field: string }
    ) => {
      const fieldName = formatFieldName({ field, label })
      const valueInNumber = Number.isNaN(value) && value ? value.length : value
      const valueInBigNumber = new BigNumberInBase(valueInNumber)

      if (valueInBigNumber.lte(min)) {
        return `${label || fieldName} needs to be greater than ${min}`
      }

      return true
    }
  )

  defineRule(
    'max',
    (
      value: string,
      [max, label]: [number | string, string | undefined],
      { field }: { field: string }
    ) => {
      const fieldName = formatFieldName({ field, label })
      const valueInNumber = Number.isNaN(value) && value ? value.length : value
      const valueInBigNumber = new BigNumberInBase(valueInNumber)

      if (valueInBigNumber.gt(max)) {
        return `${label || fieldName} cannot be greater than ${max}`
      }

      return true
    }
  )

  defineRule(
    'maxAllowedUsdAmount',
    (value: string, [max, usdPrice]: [string, string | undefined]) => {
      const usdAmountInBigNumber = new BigNumberInBase(value).multipliedBy(
        usdPrice || 0
      )

      if (usdAmountInBigNumber.gt(max)) {
        return errorMessages.maxUsdAmount(max)
      }

      return true
    }
  )

  defineRule(
    'maxBalance',
    (value: string, [max, isBalanceWithGasBuffer]: [string, string]) => {
      if (!value) {
        return true
      }

      const valueIsGreaterThanMax = new BigNumberInBase(value).gt(max)

      if (isBalanceWithGasBuffer === 'true' && valueIsGreaterThanMax) {
        return errorMessages.maxBalanceWithGasBuffer()
      }

      if (valueIsGreaterThanMax) {
        return errorMessages.maxBalance()
      }

      return true
    }
  )
}

export default defineNuxtPlugin(() => {
  defineGlobalRules()
})
