<script lang="ts" setup>
import { Modal } from '@/types'

const modalStore = useSharedModalStore()

const props = defineProps({
  isSm: Boolean,

  modal: {
    type: String as PropType<Modal>,
    required: true
  }
})

const emit = defineEmits<{
  'modal:closed': []
}>()

function onClose() {
  emit('modal:closed')
  modalStore.closeModal(props.modal)
}
</script>

<template>
  <Transition name="modal" appear>
    <SharedModalWrapper
      v-if="modalStore.modals[modal]"
      class="relative mx-auto rounded-xl bg-black shadow-sm min-w-full max-sm:h-full border border-gray-650"
      :class="[
        isSm
          ? 'sm:min-w-[400px] sm:max-w-[400px]'
          : 'sm:min-w-[480px] sm:max-w-[480px]'
      ]"
      wrapper-class="bg-gray-1000/90 sm:mt-0 modalWrapper"
      container-class="max-sm:max-h-mobileModal sm:max-h-[90vh]"
      :ignore="['.v-popper__popper']"
      v-bind="$attrs"
      @modal:closed="onClose"
    >
      <template #default="{ close, isLoading }">
        <div :class="{ 'min-h-xs flex flex-col': isLoading }">
          <div class="mr-4 mt-4">
            <slot name="icon">
              <SharedIcon
                name="close"
                class="ml-auto h-5 w-5 min-w-5 hover:text-blue-600"
                @click="close"
              />
            </slot>
          </div>

          <div v-if="isLoading" class="grow flex items-center">
            <AppSpinner is-lg primary />
          </div>
          <div v-else class="px-4 pb-6">
            <slot />
          </div>
        </div>
      </template>
    </SharedModalWrapper>
  </Transition>
</template>

<style>
.modalWrapper > div {
  @media screen and (max-width: 640px) {
    vertical-align: top;
  }
}

.modal-enter-from,
.modal-leave-to {
  @apply opacity-0;
}

.modal-leave-active .modal-container {
  transition: 300ms cubic-bezier(0.4, 0, 1, 1);
  transform: scale(0.9);
}
</style>
