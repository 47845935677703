export const isInjDomainNameSearch = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = '\\.inj'

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const isValidInjDomainName = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = /^[^\s]{3,}\.inj$/

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const isInjBonfidaDomainNameSearch = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = '\\.sol'

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const isValidInjBonfidaDomainName = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = /^[^\s]{3,}\.sol$/

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}
