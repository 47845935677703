import { default as envtnDiNBIHj7Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/env.vue?macro=true";
import { default as external_45linkUQhY3EOelYMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/external-link.vue?macro=true";
import { default as bridgeBz4U0X1bSzMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index/bridge.vue?macro=true";
import { default as fiat5lCkopcaMxMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index/fiat.vue?macro=true";
import { default as indexYEbWPVPGpzMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index/index.vue?macro=true";
import { default as wormholeHPSEHJWpD4Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index/wormhole.vue?macro=true";
import { default as indexCHkFRL2OieMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index.vue?macro=true";
import { default as maintenanceGKRr93j2JZMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/maintenance.vue?macro=true";
import { default as memes_45bridgex9Q9EH9iesMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/memes-bridge.vue?macro=true";
import { default as transfer1ZSyuq32tjMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/transfer.vue?macro=true";
import { default as wheBSHxlgtB9Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/wh.vue?macro=true";
import { default as wormhole_45migrationSWcPsaLv11Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/wormhole-migration.vue?macro=true";
export default [
  {
    name: "env",
    path: "/env",
    meta: envtnDiNBIHj7Meta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/env.vue").then(m => m.default || m)
  },
  {
    name: "external-link",
    path: "/external-link",
    meta: external_45linkUQhY3EOelYMeta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/external-link.vue").then(m => m.default || m)
  },
  {
    name: indexCHkFRL2OieMeta?.name,
    path: "/",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-bridge",
    path: "bridge",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index/bridge.vue").then(m => m.default || m)
  },
  {
    name: "index-fiat",
    path: "fiat",
    meta: fiat5lCkopcaMxMeta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index/fiat.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "index-wormhole",
    path: "wormhole",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index/wormhole.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceGKRr93j2JZMeta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "memes-bridge",
    path: "/memes-bridge",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/memes-bridge.vue").then(m => m.default || m)
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/transfer.vue").then(m => m.default || m)
  },
  {
    name: "wh",
    path: "/wh",
    meta: wheBSHxlgtB9Meta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/wh.vue").then(m => m.default || m)
  },
  {
    name: "wormhole-migration",
    path: "/wormhole-migration",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/wormhole-migration.vue").then(m => m.default || m)
  }
]