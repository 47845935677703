<script lang="ts" setup>
const props = defineProps({
  isSm: Boolean,
  isLg: Boolean,
  isXl: Boolean,
  isPrimary: Boolean,
  isGray: Boolean
})

const sizeClasses = computed<string>(() => {
  if (props.isSm) {
    return 'w-4 h-4 border-2'
  }

  if (props.isLg) {
    return 'w-8 h-8 border-4'
  }

  if (props.isXl) {
    return 'w-12 h-12 border-8'
  }

  return 'w-5 h-5 border-2'
})
</script>

<template>
  <div
    class="spinner"
    :class="[sizeClasses, { gray: isGray, primary: isPrimary }]"
  />
</template>

<style scoped>
.spinner {
  @apply block bg-transparent rounded-full top-0 border-transparent border-t-black border-r-black border-b-black;

  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spinner:not(.m-0, .mr-0) {
  @apply mx-auto;
}

.spinner:not(.mr-0) {
  @apply left-0;
}

.primary {
  border-top-color: theme('colors.blue.600');
  border-right-color: theme('colors.blue.600');
  border-bottom-color: theme('colors.blue.600');
}

.gray {
  border-top-color: theme('colors.gray.400');
  border-right-color: theme('colors.gray.400');
  border-bottom-color: theme('colors.gray.400');
}
</style>
