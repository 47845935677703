import { Page, I18nMessageFunction } from '@/types'

export default {
  bridge: {
    search: 'Search',
    selectNetwork: 'Select network',
    tradableMarkets: 'Tradable markets',
    recipientAddress: 'Recipient Address',
    destinationAddress: 'Destination Address',
    assetsInWallet: 'Your assets in the wallet',
    assetsOnInjective: 'Your assets on Injective',

    common: {
      max: 'Max',
      amount: 'Amount',
      waived: 'Waived',
      gasFee: 'Gas Fee',
      suggested: 'Suggested',
      available: 'Available',
      bridgeFee: 'Bridge Fee',
      transferAmount: 'Transfer amount',
      injAmountWithFeeBuffer: `When you click "Max", the input field shows less than your total balance because some INJ is reserved for transaction fees`
    },

    nav: {
      [Page.Index]: 'Easy Deposit/Withdraw',
      [Page.Bridge]: 'Full Bridge',
      [Page.Wormhole]: 'Wormhole Connect',
      [Page.Fiat]: 'Fiat Bridge'
    },

    wormhole: {
      error: 'Something happened. Refresh the page and try again'
    },

    fiat: {
      title: 'Deposit Fiat'
    },

    deposit: {
      title: 'Deposit',
      cta: 'Review Deposit',
      networkTitle: 'Deposit from',
      mainTitle: 'Deposit to Injective',
      successToast: 'Deposit Successful!'
    },

    withdraw: {
      title: 'Withdraw',
      cta: 'Review Withdrawal',
      networkTitle: 'Withdraw to',
      mainTitle: 'Withdraw from Injective',
      successToast: 'Withdraw Successful!'
    },

    confirm: {
      title: 'Confirm Transaction',
      description: 'Please review your transaction',
      cta: 'Confirm',
      viewOn: 'View on',
      insufficientFunds: 'Insufficient funds'
    },

    history: {
      title: 'Bridge History',
      noData: 'No transactions found',
      tooltip: 'Historical transactions'
    },

    transaction: {
      to: 'To',
      from: 'From',
      allTransactions: 'All Transactions',
      transactionHash: 'Transaction Hash'
    },

    submit: {
      setAllowance: 'Set allowance',
      approveSpend: 'Approve spend',
      insufficientGas: 'Insufficient INJ for gas',
      fetchEthereumBalance: 'Fetch Ethereum balances',
      approveSymbol: ({ named }: I18nMessageFunction) =>
        `Approve ${named('symbol')}`
    },

    evmos: {
      bridge: 'Evmos bridge',
      transfer: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          `Please navigate to the `,
          named('source'),
          ` to make this transfer.`
        ])
    },

    customPeggy: {
      add: 'Add',
      bridge: 'Bridge',
      advancedTool: 'Advanced tool',
      placeholder: 'Enter ERC-20 address',
      addAndBridgeErc20: 'Add and bridge ERC-20 token',
      manuallyAddErc20FromInjective:
        'Manually add and bridge ERC-20 token from Injective',
      manuallyAddErc20ToInjective:
        'Manually add and bridge ERC-20 token to Injective',
      addAndBridgeErc20ToInjective: 'Add and bridge ERC-20 token to Injective',
      addAndBridgeErc20FromInjective:
        'Add and bridge ERC-20 token from Injective',
      devMode: {
        addAndBridge: 'Add and bridge token',
        manuallyAddToInjective: 'Manually add and bridge token to Injective',
        manuallyAddFromInjective:
          'Manually add and bridge token from Injective',
        addAndBridgeToInjective: 'Add and bridge ERC-20 token to Injective',
        addAndBridgeFromInjective:
          'Add and bridge ERC-20 or native token from Injective'
      }
    },

    customAddress: {
      add: 'Add',
      edit: 'Edit Recipient Address',
      selectWallet: 'Select Address From Wallet',
      placeholder: ({ named }: I18nMessageFunction) =>
        `Enter ${named('network')} Address`,

      addressNotFromCex: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          'Please confirm this wallet address is not from a centralized exchange. Use ',
          named('transfer'),
          '  if you want to move assets to a centralized exchange.'
        ]),
      injectiveTransfer: 'Injective Transfer',
      checkAddress:
        'Please double check this address is correct. It is impossible to retrieve tokens from a wrong address.',
      confirmAddress: 'Confirm Address',

      // Inj Name Service
      injNameDoesNotExist: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          named('injName'),
          ' does not exist. You can claim it at ',
          named('link')
        ])
    },

    convertEth: {
      title: 'Convert ETH to WETH',
      description:
        'Convert to Wrapped Ether (WETH) before bridging to Injective',
      success: 'Successfully converted to WETH',
      convertToWeth: 'Convert to WETH'
    }
  }
}
