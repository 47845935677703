import bridge from './en/bridge'
import wallet from './en/wallet'
import transfer from './en/transfer'
import transaction from './en/transaction'
import wormhole from './en/wormhole'
import wormholeMigration from './en/wormhole-migration'

export default {
  ...bridge,
  ...wallet,
  ...wormhole,
  ...transfer,
  ...transaction,
  ...wormholeMigration,

  common: {
    ok: 'OK',
    beta: 'Beta',
    copy: 'Copy',
    search: 'Search',
    showMore: 'Show more'
  },

  layout: {
    title: 'bridge'
  },

  clipboard: {
    address: 'Address copied to clipboard'
  },

  wormholeBanner: {
    title:
      'Solana is currently experiencing congestion. Transactions to/from Solana may experience delays.'
  },

  disclaimer: {
    header: 'You’re leaving Injective Bridge',
    description: 'If you trust this 3rd party link, continue here:'
  },

  maintenance: {
    title: 'System maintenance in progress',
    description:
      'We are constantly improving the bridge. Please come back later.'
  },

  georestricted: {
    description: 'Bridge is not available in your region.',
    mercuryo: {
      title: 'Not available in your country',
      description:
        'This product is not currently available in several US states including New York, Louisiana, or Hawaii. Your proximity to these states may also affect performance. Please use at your own risk.'
    }
  }
}
