export * from './bridge'
export * from './mixpanel'

export enum Modal {
  CustomPeggy = 'custom-peggy',
  WalletQRCode = 'wallet-qr-code',
  GeoRestricted = 'GeoRestricted',
  WalletConnect = 'wallet-connect',
  MercuryoGeoWarning = 'MercuryoGeoWarning',
  PendingTransactions = 'pending-transactions',
  WalletConnectFiltered = 'wallet-connect-filtered',
  CustomRecipientAddress = 'custom-recipient-address'
}

export enum BridgeView {
  Confirm = 'confirm',
  History = 'history',
  Success = 'success',
  BridgeForm = 'bridge-form',
  ConvertEth = 'convert-eth',
  TokenSelector = 'token-selector'
}

export enum TransferView {
  TransferForm = 'transfer-form',
  TokenSelector = 'token-selector'
}

export enum CustomAddressView {
  Form = 'custom-address-form',
  Wallets = 'custom-address-wallets'
}

export enum WormholeMigrationView {
  CTA = 'cta',
  Success = 'success',
  Confirm = 'confirm',
  TokenSelector = 'token-selector'
}

export enum Page {
  Fiat = 'index-fiat',
  Index = 'index',
  Transfer = 'transfer',
  Bridge = 'index-bridge',
  Wormhole = 'index-wormhole',
  Maintenance = 'maintenance',
  WormholeMigration = 'wormhole-migration',
  MemesBridge = 'memes-bridge'
}

export enum EventBus {
  DisconnectWallet = 'disconnect-wallet'
}

export enum WormholeTransferView {
  Attest = 'attest',
  Transfer = 'transfer'
}
