import { ApolloConsumer } from '@injectivelabs/sdk-ts/graphql'
import { TokenStatic, TokenFactoryStatic } from '@injectivelabs/sdk-ts'
import { NETWORK } from '@shared/utils/constant'
import { LocalStorage } from '@injectivelabs/utils'
import { tokens } from '@/app/json'
import { PEGGY_GRAPH_URL } from '@/app/utils/constants'

export const localStorage: LocalStorage = new LocalStorage(
  `bridge-${NETWORK}-v1`
)

export const apolloConsumer = new ApolloConsumer(PEGGY_GRAPH_URL())

export const tokenFactoryStatic = new TokenFactoryStatic(
  tokens as TokenStatic[]
)
