export default {
  wormholeMigration: {
    common: {
      title: 'Migrate legacy Wormhole assets'
    },

    cta: {
      title: 'Migrate legacy wormhole assets',
      description:
        'Use this tool to migrate your legacy tokens for native tokens on Injective.',
      checkWallet: 'Check wallet',
      connectWallet: 'Connect wallet to view tokens',
      whyMigrate: 'Why do I need to migrate my tokens?',
      checkWalletForLegacy:
        'Click the button below to check your wallet for any eligible assets',
      whyMigrateDetails:
        'Injective’s new native bridge uses a more robust implementation of Wormhole to securely and efficiently transfer assets across ecosystems. Migrate any legacy tokens to native tokens as soon as possible so that you can continue to trade them on Injective.',
      migrateSubaccount:
        'Please assure that any legacy assets in your subaccount have been moved to your main account so that they can be migrated.'
    },
    tokenSelector: {
      cw20: 'CW20',
      migrate: 'Migrate',
      allSet: 'You are all set! No legacy wormhole assets in this wallet.',
      howToMigrate:
        'For each asset shown below, click migrate to send legacy assets from Injective to Wormhole and deposit the updated assets back to Injective.',
      learnMore: 'Learn more'
    },
    confirm: {
      title: 'Confirm token migration',
      description: 'Please review your transaction',
      legacyVersion: 'Legacy Version',
      migrationAmount: 'Migration Amount',
      nativeVersion: 'Native (current version)',
      successToast: 'Successfully migrating'
    },
    pleaseBePatient:
      'Please be patient. Token migration typically takes between 10 to 15 minutes, and in some cases, it may take longer due to network congestion.'
  }
}
