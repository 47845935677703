import { defineStore } from 'pinia'

type AppStoreState = {
  devMode: boolean
}

const initialStateFactory = (): AppStoreState => ({
  devMode: false
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),

  actions: {
    reset() {
      const appStore = useAppStore()

      appStore.$patch({
        devMode: false
      })
    }
  }
})
