<script lang="ts" setup>
const modalStore = useSharedModalStore()

const hasOpenModal = computed(() =>
  Object.entries(modalStore.modals).some(([, isOpen]) => isOpen)
)
</script>

<template>
  <Html
    class="bg-black text-white font-sans text-base"
    :class="{ 'overflow-y-hidden': hasOpenModal }"
  >
    <NuxtLayout />
    <ModalGeoRestricted />

    <SharedNotifications
      class="z-[100] fixed inset-0 flex flex-col gap-2 justify-end items-end p-6 pointer-events-none"
    >
      <template #default="{ notification }">
        <SharedNotification
          :notification="notification"
          class="pointer-events-auto bg-gray-700"
        >
          <template #success>
            <SharedIcon
              name="circle-check-border"
              class="min-w-5 text-blue-600 w-5 h-5"
            />
          </template>

          <template #error>
            <SharedIcon
              name="circle-close-border"
              class="min-w-5 text-red-500 w-5 h-5"
            />
          </template>

          <template #close="{ closeNotification }">
            <SharedIcon
              name="close-bold"
              is-md
              class="text-white hover:text-blue-600"
              @click="closeNotification"
            />
          </template>
        </SharedNotification>
      </template>
    </SharedNotifications>
  </Html>
</template>
