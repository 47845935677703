import { TransactionState } from '@/types'

export default {
  transaction: {
    to: 'To',
    from: 'From',
    pending: 'pending',
    complete: 'Complete',
    bridgeFee: 'Bridge Fee',
    allTransactions: 'All Transactions',
    transactionHash: 'Transaction Hash',

    [TransactionState.Completed]: 'Completed',
    [TransactionState.Failed]: 'Failed',
    [TransactionState.Cancelled]: 'Cancelled',
    [TransactionState.InProgress]: 'In Progress',
    [TransactionState.Submitted]: 'Submitted',

    viewOnExplorer: 'View on explorer',
    viewOnMintscan: 'View on mintscan',
    viewOnInjective: 'View on explorer',
    viewOnWormhole: 'View on wormholescan',
    viewOnEtherscan: 'View on etherscan',
    viewOnWildsage: 'View on wildsage',

    transactionCantBeTrackedNote: `Please do not exit this step while your Wormhole transaction is in progress. If you exit this step, you can always claim your funds **after** the transaction is confirmed on Wormhole using the "Redeem Wormhole Tx" icon on the main page`,

    success: {
      title: 'Your transaction has been submitted',
      returnToHelix: 'Return to Helix',
      confirmTitle: 'Your transaction has been confirmed',
      cosmosWithdrawalTransaction:
        'Tracking this transaction on this page is currently unavailable',
      wormholeConfirmationUntrackable:
        'Tracking migration completion on this page is currently unavailable. Please check your recent transactions periodically for when the transfer is complete'
    },

    steps: {
      submitted: 'Transaction submitted',
      completed: 'Transaction completed',
      ethereumConfirming: 'Confirming on Ethereum',
      confirmedOnEthereum: 'Confirmed on Ethereum',
      injectiveConfirming: 'Confirming on Injective',
      redeemable: 'Wormhole transaction redeemable',
      wormholeConfirmation: 'Wormhole Confirmation'
    },

    title: {
      [TransactionState.InProgress]: 'Transaction in progress',
      [TransactionState.Completed]: 'Transaction completed',
      [TransactionState.Failed]: 'Transaction failed'
    }
  }
}
